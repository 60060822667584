/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rady a tipy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Poradna"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ouzh41xezf"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Zadlabací zámek"}>
              </Title>

              <Text className="text-box" content={"Pro správný výběr zadlabacího zámku je potřeba znát několik parametrů s kterými Vám rádi pomůžeme"}>
              </Text>

              <Button className="btn-box" content={"Měříme zámek"} use={"page"} href={"/zamek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Cylindrická vložka"}>
              </Title>

              <Text className="text-box" content={"Cylindrické vložky se vyrábějí v různých délkách a bezpečnostních třídách&nbsp;"}>
              </Text>

              <Button className="btn-box" content={"Vybíráme vložku"} use={"page"} href={"/vlozka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-14bc1xq pb--20 pt--80" layout={"l1"} name={"65jbm2j84r"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Váš&nbsp;KLÍČNÍK</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: var(--white);\">Miroslav Košůtek\n<br>Náplatková 216/12, 747 23,Bolatice\n<br>IČ: 86670565\n<br>DIČ: CZ7302125435</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}